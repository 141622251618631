import { type AnchorHTMLAttributes } from "react";
import { Link, type LinkProps } from "@remix-run/react";
import { type VariantProps } from "class-variance-authority";

import { buttonStyles } from "./Button.tsx";
import { twMerge } from "tailwind-merge";

export interface ButtonProps
  extends LinkProps,
    AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonStyles> {}

export const LinkButton: React.FC<ButtonProps> = ({
  className,
  intent,
  size,
  children,
  ...props
}: ButtonProps) => {
  return (
    <Link
      className={twMerge(buttonStyles({ intent, size, className }))}
      {...props}
    >
      {children}
    </Link>
  );
};
